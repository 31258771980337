const sidebarOrder = [
    '/',
    '/manual/introduction/style',
    '/manual/introduction/overall',
    '/manual/introduction/menu',
    '/manual/introduction/login',
    '/manual/system/account-role-authorities',
    '/manual/system/project-role-authorities',
    '/manual/system/company-settings',
    '/manual/system/calendar-settings',
    '/manual/system/numbering-patterns',
    '/manual/system/authentication-settings',
    '/manual/system/erp-link-settings',
    '/manual/system/custom-field-settings',
    '/manual/system/workflow-settings',
    '/manual/system/accounts',
    '/manual/system/domains',
    '/manual/system/mail-settings',
    '/manual/system/work-patterns',
    '/manual/system/organization',
    '/manual/system/input-prohibited-dept-sets',
    '/manual/system/data-export',
    '/manual/system/data-import',
    '/manual/system/auto-import-settings',
    '/manual/system/auto-import-settings-cloud',
    '/manual/integration/project-types',
    '/manual/integration/determinacy-setting',
    '/manual/integration/projects',
    '/manual/integration/workflow-history',
    '/manual/integration/customers',
    '/manual/integration/tickets',
    '/manual/integration/common-settings',
    '/manual/integration/analysis-group-setting',
    '/manual/integration/monthly-cut-off',
    '/manual/integration/current-month-input-prohibited-dept-sets',
    '/manual/scope/functions',
    '/manual/scope/processes',
    '/manual/scope/documents',
    '/manual/schedule/gantt',
    '/manual/schedule/subfuncs',
    '/manual/schedule/gantt-field-settings',
    '/manual/schedule/progress-settings',
    '/manual/schedule/milestone-types',
    '/manual/schedule/milestones',
    '/manual/schedule/progress-reports',
    '/manual/schedule/pj-progress-reports',
    '/manual/schedule/weekly-reports',
    '/manual/cost/man-calc-rules',
    '/manual/cost/function-group-man-hours',
    '/manual/cost/quotes',
    '/manual/cost/working-hours',
    '/manual/cost/man-hours',
    '/manual/cost/emp-rank',
    '/manual/cost/cost-recording-settings',
    '/manual/cost/expenses',
    '/manual/cost/work-in-progress',
    '/manual/cost/profits',
    '/manual/cost/dept-profits',
    '/manual/cost/cost-items',
    '/manual/cost/cost-group-expenses',
    '/manual/cost/cost-groups',
    '/manual/cost/purchase-budget-control',
    '/manual/quality/quality-test',
    '/manual/quality/defects',
    '/manual/quality/quality',
    '/manual/quality/review-management',
    '/manual/quality/review',
    '/manual/personnel/members',
    '/manual/personnel/resource-histogram',
    '/manual/personnel/assignment',
    '/manual/personnel/organization-change-indication',
    '/manual/personnel/members-skill-eval',
    '/manual/communication/questions',
    '/manual/communication/inquiries',
    '/manual/risk/risks',
    '/manual/risk/problems',
    '/manual/procurement/contractors',
    '/manual/procurement/contractors-agreement',
    '/manual/procurement/consignment-expenses/slips',
    '/manual/report/analysis-report-orders',
    '/manual/common/operation',
    '/manual/common/label-change-manual',
    '/manual/other/mfa-settings-manual',
    '/manual/other/mfa-settings',
    '/manual/other/saml-settings-manual',
    '/manual/other/input-prohibited-dept',
    '/manual/other/project-dialog',
    '/manual/other/customer-dialog',
    '/manual/other/consignee-dialog',
    '/manual/other/dept-dialog',
    '/manual/other/act-dialog',
    '/manual/other/attached-file-dialog',
    '/manual/other/project-group-dialog',
    '/manual/other/summary-dept-dialog',
    '/manual/other/copyright'
];

export default sidebarOrder;